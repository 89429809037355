import React, { Component } from 'react';
import Layout from '../components/layout';
import BackgroundCanvas from '../components/background-canvas-component';

import './index.scss';
import { i18n } from '../components/i18n';
import { withTranslation } from 'react-i18next';

let bgImage = require('../images/Government-of-Childrem-v1.png');
let titleImg = require('../images/title.png');
let timelineImg = require('../images/timeline GoC.png');

let burgerImg = require('../images/menu-button-of-three-horizontal-lines.png');
let downArrow = require('../images/down-arrow.png');

let mailImg = require('../images/email-icon.png');
let instaImg = require('../images/instagram.png');
let facebookImg = require('../images/facebook-logo-button.png');
let twitterImg = require('../images/twitter-logo-button.png');
let youtubeImg = require('../images/youtube.png');
let vimeoImg = require('../images/vimeo-social-logo.png');

let goc1 = require('../images/GoC 10.png');
let goc2 = require('../images/GoC 12.png');
let goc3 = require('../images/GoC 3.png');
let goc4 = require('../images/GoC 18.png');
let goc5 = require('../images/GoC 5.png');

let logo_fne = require('../images/film new europe.png');
let logo_hr = require('../images/hollywood reporter.png');
let logo_ii = require('../images/itsy bitsy.png');
let logo_jpc = require('../images/jurnal pentru copii.png');

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
            isMenuVisible: false
        };

        this.listenToScroll = this.listenToScroll.bind(this);
        this.switchLeftNavVisible = this.switchLeftNavVisible.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll);
    }

    listenToScroll() {
        if (window.pageYOffset > 400 && !this.state.isScrolled) {
            this.setState({ isScrolled: true });
        } else if (window.pageYOffset <= 300 && this.state.isScrolled) {
            this.setState({ isScrolled: false });
        }
    }

    switchLeftNavVisible() {
        this.setState({ isMenuVisible: !this.state.isMenuVisible }, () => {
            console.log(this.state);
        });
    }

    getAbout(i18n) {
        if (i18n.language === 'ro') {
            return (
                <div>
                    <p className="half">
                        <p>
                            <b>Government of Children</b> este o franciză transmedia unică în lume (film 3D / web
                            platform / VR), creată de <b>Ioana Mischie</b>, Fulbright Alumna, care își propune să
                            încurajeze copiii din întreaga lume să se considere lideri în formare și să își imagineze
                            viitorul societății în care vor trăi.{' '}
                        </p>
                        <br />
                        <p>
                            <a href="https://vimeo.com/336105726">
                                Filmul Guvernul Copiilor 3D (Government of Children 3D)
                            </a>
                            este primul film documentar 3D realizat în România care aduce împreună 100 de copii
                            vizionari pentru a-și imagina cum ar evolua societatea în următorii 100 de ani. Începând cu{' '}
                            <b>1 iunie</b>, vino să descoperi în cinematografe lumea în care și-ar dori copiii să
                            trăiască!
                        </p>
                        <br />
                        <p>
                            <a href="https://www.governmentofchildren.com/ro/">GoC Interactive</a> este platforma web a
                            proiectului Government of Children, unde copiii din întreaga lume pot contribui cu viziunile
                            lor în timp real, pot explora lumea văzută prin ochii celorlalți și se pot bucura de
                            extensiile VR Tangible Utopias.{' '}
                        </p>
                        <br />
                        <p>
                            <a href="https://www.governmentofchildren.com/en/inhabit/">Tangible Utopias</a> este
                            platforma VR a proiectului care extinde viziunile copiilor într-un oraș virtual
                            computer-generated, pe care userii îl pot explora - ca o galerie a viitorurilor posibile.
                        </p>
                    </p>
                    <img className="section-thumbnail" src={goc1} />
                </div>
            );
        } else
            return (
                <div>
                    <p className="half">
                        Government of Children is a pioneering transmedia documentary franchise (3D Film / Web / VR)
                        aiming to empower children from all over the world to see themselves as young leaders, to
                        imagine their societal future and to share their long-term views - how would education, health
                        care, transporation, technology evolve in the future from their perspectives? How would they
                        solve real-world problems such as gun violence, homelessness, extreme poverty? Children have no
                        rights to vote in our societies, however their vision if heard and taken seriously, can shape
                        their own community in unprecedented ways. Their visions would be discussed and archived in an
                        ongoing documentary film with immersive and interactive extensions, addressed to young
                        generations (but also secretly aiming to reach the adulthood). We are inviting visionary
                        children of 6-12 years old, from a diversity of ethnic, social, educational backgrounds. Each
                        child will be invited to share their vision of the future and to co-create the visual inserts
                        representing their views. We will personalize the set design depending on their chromatic
                        preferences.
                    </p>
                    <img className="section-thumbnail" src={goc1} />
                    <ul>
                        {/* <li>
                            <a href="https://vimeo.com/336105726">3D Film Trailer</a>
                        </li> */}
                        <li>
                            <a href="https://www.governmentofchildren.com/en/become/">GoC Participatory Playground</a>
                        </li>
                        <li>
                            <a href="https://www.governmentofchildren.com/en/explore/">
                                GoC Exploratory Web Playground
                            </a>
                        </li>
                        <li>
                            <a href="https://www.governmentofchildren.com/en/inhabit/">Tangible Utopias VR</a>
                        </li>
                    </ul>
                </div>
            );
    }

    getTeams(i18n) {
        if (i18n.language === 'ro') {
            return (
                <ul>
                    <li>
                        <a href="/team">Echipa filmului stereoscopic</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=website-team">Echipa website-ului</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=vr-team">Echipa proiectului VR</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=biographies">Biografii ale echipei GoC</a>
                    </li>
                </ul>
            );
        } else {
            return (
                <ul>
                    <li>
                        <a href="/team">Stereoscopic Movie Team</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=website-team">Website Team</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=vr-team">VR Project Team</a>
                    </li>
                    <li>
                        <a href="team?scrollTo=biographies">GoC Team Biographers</a>
                    </li>
                </ul>
            );
        }
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <Layout>
                <div className="index_wrapper">
                    {this.state.isScrolled && (
                        <div className="burger-menu" onClick={this.switchLeftNavVisible}>
                            <img src={burgerImg} />
                        </div>
                    )}
                    {this.state.isMenuVisible && (
                        <nav className="left-nav">
                            <ul>
                                <a href="#about">
                                    <li>About</li>
                                </a>
                                <a href="#director-statement">
                                    <li>Director's Statement</li>
                                </a>
                                <a href="#team">
                                    <li>Team</li>
                                </a>
                                <a href="#3d-film">
                                    <li>The 3D Film</li>
                                </a>
                                <a href="#ambassadors">
                                    <li>Goc Ambassadors</li>
                                </a>
                                <a href="#media-coverage">
                                    <li>Media Coverage</li>
                                </a>
                                <a href="#donate">
                                    <li>Donate</li>
                                </a>
                                <a href="#timeline">
                                    <li>Timeline</li>
                                </a>
                                <a href="#faq">
                                    <li>FAQ</li>
                                </a>
                                <a href="#share">
                                    <li>Share</li>
                                </a>
                            </ul>
                        </nav>
                    )}
                    <img className="key-visual" alt="Key visual" src={bgImage} />
                    <BackgroundCanvas />
                    <img className="title-img" src={titleImg} />

                    <a href="#about" className="down-arrow">
                        <img src={downArrow} />
                    </a>

                    <section id="about">
                        <h1>About</h1>
                        {this.getAbout(i18n)}
                    </section>
                    <section id="trailer">
                        <h1>Trailer</h1>
                        <iframe
                            className="vimeo"
                            src="https://player.vimeo.com/video/336105726"
                            width="640"
                            height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen
                        ></iframe>
                    </section>

                    <section id="director-statement">
                        <h1> Director's Statement</h1>
                        <img className="section-thumbnail left" src={goc5} />
                        <p className="half right">{t('director_statement')}</p>
                    </section>

                    <section id="team">
                        <h1>Team</h1>
                        <p className="half">{t('team_desc')}</p>
                        <img className="section-thumbnail" src={goc3} />
                        {this.getTeams(i18n)}
                    </section>

                    <section id="3d-film">
                        <h1>The 3D Film</h1>
                        <img src={goc4} className="section-thumbnail left" />
                        <p className="half right">
                            Ți-ai imaginat vreodată cum ar arăta țara noastră dacă ar fi condusă de copii? Ce măsuri ar
                            lua ei pentru a face oamenii mai fericiți? Cum ar încerca să combată poluarea, conflictele
                            sau inegalitatea socială? Ei, bine, avem sute de idei pentru un viitor mai bun! Filmul
                            Guvernul Copiilor este primul documentar 3D realizat în România care aduce împreună 100 de
                            copii vizionari pentru a-și imagina cum ar evolua societatea în următorii 100 de ani.
                            Începând cu 1 iunie, vino să descoperi în cinematografe lumea în care și-ar dori copiii să
                            trăiască!
                        </p>
                        <ul className="half right film-3d">
                            <li>
                                <a href="https://docs.google.com/document/d/1-PxjF1ex6a8-13wA9IC7P_CJaKJkSVYr5zGtZWRieR4/edit?usp=sharing">
                                    Săli de cinema în care filmul Government of Children 3D / Guvernul Copiilor 3D va
                                    putea fi vizionat din 31 mai 2019
                                </a>
                            </li>
                            <li>
                                <a href="https://docs.google.com/document/d/16jRNpmncPrJAnMestWNp1OLeemcpbYcsz_yTs1AoRAY/edit?usp=sharing">
                                    Proiecții-eveniment în prezența echipei
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section id="ambassadors">
                        <h1>GoC Ambassadors</h1>
                        <p className="half">
                            {t('goc_ambassadors_1')}
                            <a href="">{t('goc_ambassadors_2')}</a>
                            {t('goc_ambassadors_3')}
                        </p>
                        <img src={goc2} className="section-thumbnail" />
                    </section>

                    {/* <section>
                        <h1>News</h1>
                    </section> */}

                    <section id="media-coverage">
                        <h1>Media Coverage</h1>
                        <h3>International</h3>
                        <div className="logos">
                            <a href="https://www.hollywoodreporter.com/lists/5-key-eastern-european-regions-luring-budget-conscious-global-producers-1184637">
                                <img src={logo_hr} />
                            </a>
                            <a href="https://www.filmneweurope.com/news/romania-news/item/116820-fne-av-innovation-romanian-fulbrighter-ioana-mischie-in-production-with-pioneering-transmedia-franchise-with-state-of-the-art-stereoscopic-technology">
                                <img src={logo_fne} />
                            </a>
                        </div>
                        <h3>National</h3>
                        <div className="logos">
                            <a href="https://www.youtube.com/watch?v=frD1tF2V8Bo&feature=youtu.be&fbclid=IwAR31TCAnoKjjnNC9r5V2vGHJKZ-VTX6MvNYHdkQd4XcbOWbGrarW4KCXsb4">
                                <img src={logo_ii} />
                            </a>
                            <a href="https://www.digi24.ro/emisiuni/jurnal-pentru-copii/jurnal-pentru-copii-8-septembrie-09-30-993170">
                                <img src={logo_jpc} />
                            </a>
                        </div>
                    </section>

                    <section id="donate">
                        <h1>Donate</h1>
                        <p>{t('donate')}</p>
                    </section>

                    <section id="timeline">
                        <h1>Timeline</h1>
                        <img src={timelineImg} />
                    </section>

                    <section id="faq">
                        <h1>FAQ</h1>
                        <h3>Cât au durat filmările Government of Children 3D?</h3>
                        <p>
                            Filmarea propriu-zisă a însumat 11 zile intensive, timp în care ne-au trecut pragul peste
                            100 de copii. Post-producția filmului stereoscopic a însumat 11 luni de zile.
                        </p>

                        <h3>Ce anume vizează Government of Children 3D?</h3>
                        <p>
                            Universul transmedia Government of Children încurajează imaginația civică a copiilor,
                            arhivarea viziunilor lor de leadership și adoptarea unor viziuni constructive în societate.
                            Este un proiect artistic, educațional, tehnologic unic în lume.
                        </p>

                        <h3>Ce tehnologii inovatoare folosește Government of Children 3D?</h3>
                        <p>
                            Filmul stereoscopic Government of Children 3D a folosit pentru prima dată în lume Alpha 3D
                            rig, o tehnologie stereoscopică inițiată de Stereographer, din Budapesta, Ungaria.
                        </p>

                        <h3>Cum a fost finanțat filmul stereoscopic Guvernul Copiilor 3D?</h3>
                        <p>
                            Filmul Guvernul Copiilor 3D a fost creat independent, fiind produs de Storyscapes și
                            Studioset, cu sprijin tehnologic din partea UNATC-CINETic, dat fiind faptul că proiectul
                            este parte dintr-o lucrare doctorală. Ulterior, pentru etapa de distribuție, proiectul a
                            obținut un sprijin minim de la CNC și UCIN.
                        </p>
                    </section>

                    <section id="share">
                        <h1>Share</h1>

                        <p>{t('share')}</p>
                        <div className="social">
                            <a href="mailto:governmentofchildren@gmail.com">
                                <img src={mailImg} />
                            </a>
                            <a href="https://www.facebook.com/GovernmentOfChildrenRomania/">
                                <img src={facebookImg} />
                            </a>
                            <a href="https://www.instagram.com/governmentofchildren/">
                                <img src={instaImg} />
                            </a>
                            <a href="https://twitter.com/GovOfChildren?lang=en">
                                <img src={twitterImg} />
                            </a>
                            <a href="https://www.youtube.com/watch?v=vlREs908gZw">
                                <img src={youtubeImg} />
                            </a>
                            <a href="https://vimeo.com/336105726">
                                <img src={vimeoImg} />
                            </a>
                        </div>
                    </section>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(IndexPage);
